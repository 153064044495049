@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '@splidejs/react-splide/css';

.splide__pagination {
  opacity: 0;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

img {
  pointer-events: none;
}

.rich-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rich-text ul {
  list-style-type: disc;
}

.rich-text ol {
  list-style-type: decimal;
}

.rich-text ul,
ol {
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rich-text h2,
h3 {
  font-weight: bold;
}

.rich-text h2 {
  font-size: 1.5rem;
}
